import * as React from "react"
import { Link } from "gatsby"

import Video from '../../images/video/paju-bg.mp4'
import Poster from '../../images/video/paju-bg.jpg'

import { StaticImage } from "gatsby-plugin-image"
import RTLogo from '../../images/rt-logo.svg'
import ContactLauriNo from "../../components/ContactLauriNo";

import HeaderNo from '../../components/HeaderNo'

import LayoutNo from "../../components/LayoutNo"
import Seo from "../../components/Seo"
import FactoryTourNo from "../../components/FactoryTourNo"
import SampleProjectsNo from "../../components/SampleProjectsNo"


const IndexPage = () => {

  return (
    <LayoutNo>
      <Seo title="Hjem" description="Welement er en partner som hjelper entreprenører, ingeniører og utviklere med å videreutvikle byggenæringen" lang="no" />

      <HeaderNo />
      <div className="relative">
        <video autoPlay muted loop className="h-96 md:h-128 w-full object-cover">
          <source src={Video} poster={Poster} type="video/mp4" />
        </video>

        <div className="absolute top-0 left-0 w-full h-full bg-welement-black/40 text-welement-white">

          <div className="m-8 sm:m-16 md:m-20">
            <div className="md:w-3/4">
              <h1 className="title-xs wel-mb-s">
                ENTREPRENØR PÅ BYGGEPLASSSEN
              </h1>
              <h2 className="body-xl wel-mb-m">
              Welement er en partner som hjelper entreprenører, ingeniører og utviklere med å videreutvikle byggenæringen
              </h2>

              <Link to="/no/solutions" className="btn btn-white">
              Våre løsninger
              </Link>
            </div>
          </div>
        </div>

      </div>

      <div className="container-base">

        <div className="text-center md:w-3/4 mx-auto wel-py-l">
          <h2 className="title-xs">
          HVA GJØR VI
          </h2>
          <p className="body-xl">
          Welement tilbyr løsninger for prefabrikerte elementer innen trevirke.
          </p>

        </div>


      </div>

      <div className="container mx-auto text-center wel-mb-l">

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-16">
          <div className="text-center">
            <StaticImage
              src="../../images/solutions/eramaja.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Eneboliger"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Eneboliger</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/solutions/ridaelamud.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Rekkehus"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Rekkehus</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/solutions/korter.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Leilighetsbygg"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Leilighetsbygg</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/solutions/fassaad.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Fasadesystemer"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Fasadesystemer</p>
          </div>

        </div>

        <Link to="/no/solutions" className="btn btn-red">Utforsk løsninger</Link>


      </div>

      <FactoryTourNo />

      <div className="container-base">
        <div className="mx-auto text-center w-3/4">
          <img src={RTLogo} alt="Rand &amp; Tuulberg" className="h-10 mx-auto mb-8" />

          <p className="body-l wel-mb-m">Welement er en del av Estiske Rand og Tuulberg Group som har over 23 års erfaring innen byggebransjen, eiendomsutvikling og produksjon av byggningsmateriell.</p>
          <Link to="/no/our-story" className="btn btn-black">Les vår historie</Link>

        </div>
      </div>

      <div className="bg-welement-pink">
        <SampleProjectsNo />
      </div>

      <ContactLauriNo />


    </LayoutNo>
  )

};

export default IndexPage;

